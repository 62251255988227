import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from './store';

const App = lazy(() => import('./App'));

const root = createRoot(document.getElementById('root')!);

const qclient = new QueryClient();
root.render(
	<BrowserRouter>
		<QueryClientProvider client={qclient}>
			<Provider store={store}>
				<Suspense fallback={<div>Loading Up...</div>}>
					<App />
				</Suspense>
			</Provider>
		</QueryClientProvider>
	</BrowserRouter>
);
