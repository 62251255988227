import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

interface User {
	displayName: string;
	email: string;
	photoURL: string;
	uid: string;
}

export interface UserState {
	accessToken: string | null;
	user: User | null;
}

const initialState: UserState = {
	accessToken: null,
	user: null
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string | null>) => {
			state.accessToken = action.payload;
		},
		setUser: (state, action: PayloadAction<User | null>) => {
			state.user = action.payload;
		},
		logOut: (state) => {
			state.accessToken = null;
			state.user = null;
		}
	}
});

export const { setAccessToken, setUser, logOut } = userSlice.actions;

export const getAccessToken = (state: RootState) => state.user.accessToken;
export const getUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
